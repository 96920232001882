<ng-container *ngIf="!(canDownload$ |async); then cannot_download; else can_download">
</ng-container>

<ng-template #can_download>
    <ng-container *ngIf="galleryInfo.galleryItem; then gallery_item; else not_gallery_item">
    </ng-container>


    <ng-template #gallery_item>
			<a (click)="showGallery( $event )" href="{{(bitstreamPath$| async)?.routerLink}}" class="dont-break-out fancybox-dspimgs">
				<ng-container *ngTemplateOutlet="content"></ng-container>
			</a>
    </ng-template>

    <ng-template #not_gallery_item>
	<ng-container *ngIf="isPdf(); then pdf_item; else download_item">
	</ng-container>


	<ng-template #pdf_item>
	    <a #link href="{{(bitstreamPath$| async)?.routerLink}}" (click)="showPdf( $event, link.href)" class="dont-break-out">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	    </a>
	</ng-template>


	<ng-template #download_item>
	    <a [routerLink]="(bitstreamPath$| async)?.routerLink" class="dont-break-out" [queryParams]="(bitstreamPath$| async)?.queryParams" [target]="isBlank ? '_blank': '_self'" [ngClass]="cssClasses">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	    </a>
	</ng-template>


    </ng-template>





</ng-template>

<ng-template #cannot_download>
    <a [routerLink]="(bitstreamPath$| async)?.routerLink" class="dont-break-out" [queryParams]="(bitstreamPath$| async)?.queryParams" [target]="isBlank ? '_blank': '_self'" [ngClass]="cssClasses">
	<span class="pr-1"><i class="fas fa-lock"></i></span>
	<ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
</ng-template>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>