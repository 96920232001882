export class FileUtil{
    private static imageExtensions: string[] = [ 'jpg', 'jpeg', 'png' ];

    private static videoExtensions: string[] = [ 'mp4', 'avi' ];

    /// Tells if we consider this file an image
    public static isImage( fileName: string ): boolean {
        for ( let ext of FileUtil.imageExtensions ){
            if ( fileName.endsWith( ext ) ){
                return true;
            }
        }

        return false;
    }

    /// Tells if we consider this file a video
    public static isVideo( fileName: string ): boolean {
        for ( let ext of FileUtil.videoExtensions ) {
            if ( fileName.endsWith( ext ) ) {
                return true;
            }
        }

        return false;
    }
}
