<div class="hp-welcome-container mt-ncs">
    <div class="container">
      <div class="hp-welcome-text-container">
        <p class="bea_welcome_text_h1">{{ 'home-news.lead' | translate }}</p>
        <p class="bea_welcome_text_p">{{ 'home-news.text' | translate }}</p>
      </div>
    </div>

  <picture>
    <source media="(min-width: 1200px)" srcset="assets/dspace/images/bea_banner_full.jpg">
    <source media="(min-width: 768px)" srcset="assets/dspace/images/bea_banner_768px.jpg">

    <!-- without the []="''" Firefox downloads both the fallback and the resolved image -->
    <img class="hp-welcome-bg-image" alt="" [src]="'assets/dspace/images/bea_banner_500px.jpg'"/>
  </picture>
</div>
