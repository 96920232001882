import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchService } from '../../../../app/core/shared/search/search.service';

/**
 * Component representing the BEA search navbar
 */
@Component({
  selector: 'bea-search-navbar',
  styleUrls: ['./bea-search-navbar.component.scss'],
  templateUrl: './bea-search-navbar.component.html'
})
export class BEASearchNavbarComponent
{
  searchForm;

  constructor( private formBuilder: UntypedFormBuilder,
                private router: Router,
                private searchService: SearchService)
  {
    this.searchForm = this.formBuilder.group(({ query: '', }));
  }

  onSubmit(data: any)
  {
    const queryParams = Object.assign({}, data);
    const linkToNavigateTo = [this.searchService.getSearchLink().replace('/', '')];
    this.searchForm.reset();
    this.router.navigate(linkToNavigateTo, {
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }
}
