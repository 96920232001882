<header class="header">
  <nav role="navigation" [attr.aria-label]="'nav.user.description' | translate" class="container navbar navbar-expand-md px-0">
    <div class="d-flex flex-grow-1">
      <a class="navbar-brand m-2" routerLink="/home">
        <div class="bea_brand">
          <img class="bea_brand_img" src="assets/images/bea_brand_logo.png" [attr.alt]="'menu.header.image.logo' | translate"/>
          <div class="bea_title_text"><span class="bea_title_text_firstletter">B</span><span>{{ 'header.logo-text-first' | translate }}</span><br><span class="bea_title_text_firstletter">E</span>{{ 'header.logo-text-second' | translate }}<br><span class="bea_title_text_firstletter">A</span><span>{{ 'header.logo-text-third' | translate }}</span></div>
        </div>
      </a>
    </div>
    <div class="bea_search_container">
      <bea-search-navbar></bea-search-navbar>
    </div>
    
    <div class="navbar-buttons d-flex flex-grow-1 ml-auto justify-content-end align-items-center gapx-1">
      <ds-themed-lang-switch></ds-themed-lang-switch>
      <ds-context-help-toggle></ds-context-help-toggle>
      <ds-themed-auth-nav-menu></ds-themed-auth-nav-menu>
      <ds-impersonate-navbar></ds-impersonate-navbar>
      <div class="pl-2">
        <button class="navbar-toggler" type="button" (click)="toggleNavbar()"
                aria-controls="collapsingNav"
                aria-expanded="false" [attr.aria-label]="'nav.toggle' | translate">
          <span class="navbar-toggler-icon fas fa-bars fa-fw" aria-hidden="true"></span>
        </button>
      </div>
    </div>
  </nav>
  <ds-themed-navbar></ds-themed-navbar>

</header>
