import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PdfViewerService {

  private currentPdf = new Subject<string>();
  currentPdf$ = this.currentPdf.asObservable();

  constructor() { }

  public setCurrentPdf( currentPdf : string )
  {
    this.currentPdf.next( currentPdf );
  }
}
