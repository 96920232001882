<nav [ngClass]="{'open': !(menuCollapsed | async)}" [@slideMobileNav]="!(windowService.isXsOrSm() | async) ? 'default' : ((menuCollapsed | async) ? 'collapsed' : 'expanded')"
  class="navbar navbar-expand-md navbar-light p-0 navbar-container" role="navigation" [attr.aria-label]="'nav.main.description' | translate">
  <div class="navbar-inner-container w-100 h-100" [class.container]="!(isXsOrSm$ | async)">
    <a class="navbar-brand my-2" routerLink="/home">
      <div class="bea_brand">
        <img style="vertical-align: baseline;" src="assets/images/bea_brand_logo.png" [attr.alt]="'menu.header.image.logo' | translate" />
        <div class="bea_title_text"><span class="bea_title_text_firstletter">B</span><span>{{ 'header.logo-text-first' | translate }}</span><br><span class="bea_title_text_firstletter">E</span>{{ 'header.logo-text-second' | translate }}<br><span class="bea_title_text_firstletter">A</span><span>{{ 'header.logo-text-third' | translate }}</span></div>
      </div>
    </a>

    <div style="display: flex; flex-flow: column wrap; justify-content: flex-end;">

    <div style="display: flex; flex-flow: row nowrap;">

    <div id="collapsingNav" class="w-100 h-100">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 h-100">
        <li *ngIf="(isXsOrSm$ | async) && (isAuthenticated$ | async)">
            <ds-user-menu [inExpandableNavbar]="true"></ds-user-menu>
        </li>
        <li *ngFor="let section of (sections | async)">
          <ng-container *ngComponentOutlet="(sectionMap$ | async).get(section.id)?.component; injector: (sectionMap$ | async).get(section.id)?.injector;"></ng-container>
        </li>

        <li><a href="#" class="nav-item nav-link">{{ 'nav-terms-of-use' | translate }}</a></li>
        <li><a href="#" class="nav-item nav-link">{{ 'nav.aboutus' | translate }}</a></li>
      </ul>
    </div>
    
    <div class="navbar-buttons d-flex align-items-center gapx-1">
      <ds-themed-lang-switch class="navbar-collapsed"></ds-themed-lang-switch>
      <ds-context-help-toggle class="navbar-collapsed"></ds-context-help-toggle>
      <ds-themed-auth-nav-menu class="navbar-collapsed"></ds-themed-auth-nav-menu>
      <ds-impersonate-navbar class="navbar-collapsed"></ds-impersonate-navbar>
    </div>
    </div>
    <bea-search-navbar class="navbar-collapsed"></bea-search-navbar>
    
    </div>
  </div>
</nav>
