<div *ngIf="isVisible()" class="mypdfviewer">
    <div (click)="hideViewer()" class="viewer-overlay"></div>

    <div class="viewer-toolbar" style="width: 100%; min-height: 3%;">
        <button (click)="hideViewer()" style="position:absolute; top: 0px; right: 0px;">X</button>
    </div>

    <div class="pdf-viewer">
        <ng2-pdfjs-viewer [pdfSrc]="pdfDocument"></ng2-pdfjs-viewer>
    </div>
</div>
