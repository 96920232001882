<footer>
	<div class="container">
		<div class="bea_footer_container" >
			<div class="bea_footer_item"><img class="bea_footer_image" src="assets/dspace/images/logos/footer_logo_fszek_tall.jpg"></div>
			<div class="bea_footer_contacts bea_footer_item">
				<ul>
					<li>1088 Budapest, Szabó Ervin tér 1.</li>
					<li><a href="tel:+3614115000">06 1 411-5000</a></li>
					<li><a href="mailto:info@fszek.hu">info@fszek.hu</a></li>
					<li><a href="https://fszek.hu">https://fszek.hu</a></li>
				</ul>
			</div>
			<div class="bea_footer_social_container bea_footer_item" >
				<div class="bea_footer_social_inner_container" >
					<div>
						<a href="https://www.facebook.com/fszek.hivatalos/"><img class="bea_footer_social_icon" src="assets/dspace/images/logos/footer_logo_facebook.png"></a>
						<img class="bea_footer_social_icon" src="assets/dspace/images/logos/footer_logo_insta.png">
					</div>
					<div>
						<a href="https://www.youtube.com/channel/UCcyhdAHZ1QHmSz1C1OwzK_g"><img class="bea_footer_social_icon" src="assets/dspace/images/logos/footer_logo_youtube.png"></a>
						<img class="bea_footer_social_icon" src="assets/dspace/images/logos/footer_logo_spotify.png">
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
