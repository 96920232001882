import { Component, ElementRef, inject, Input, OnInit, Renderer2 } from '@angular/core';
import { Bitstream } from '../../core/shared/bitstream.model';
import { getBitstreamDownloadRoute, getBitstreamRequestACopyRoute } from '../../app-routing-paths';
import { AuthorizationDataService } from '../../core/data/feature-authorization/authorization-data.service';
import { FeatureID } from '../../core/data/feature-authorization/feature-id';
import { hasValue, isNotEmpty } from '../empty.util';
import { map } from 'rxjs/operators';
import { of as observableOf, combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { Item } from '../../core/shared/item.model';

import { DSONameService } from '../../core/breadcrumbs/dso-name.service';

import { PdfViewerService } from '../pdf-viewer/pdf-viewer.service';
import { FileUtil } from '../gallery/fileutil';
import { GalleryService } from '../gallery/gallery.service';

@Component({
  selector: 'ds-file-gallery-link',
  templateUrl: './file-gallery-link.component.html',
  styleUrls: ['./file-gallery-link.component.scss']
})
/**
 * Component displaying file links which star a gallery
 * When the user is authenticated, a short-lived token retrieved from the REST API is added to the download link,
 * ensuring the user is authorized to download the file.
 */
export class FileGalleryLinkComponent implements OnInit {

  /**
   * Optional bitstream instead of href and file name
   */
  @Input() bitstream: Bitstream;

  @Input() item: Item;

  /**
   * Additional css classes to apply to link
   */
  @Input() cssClasses = '';

  /**
   * A boolean representing if link is shown in same tab or in a new one.
   */
  @Input() isBlank = false;

  @Input() enableRequestACopy = true;

  private galleryService: GalleryService = inject( GalleryService );

  galleryInfo = {
    galleryItem: false,
    image: false,
    video: false
  };

  xmluiPath = '';

  bitstreamPath$: Observable<{
    routerLink: string,
    queryParams: any,
  }>;

  canDownload$: Observable<boolean>;

  private element: ElementRef = inject(ElementRef);
  private renderer: Renderer2 = inject(Renderer2);

  constructor(
    private authorizationService: AuthorizationDataService,
    private dsoNameService: DSONameService,
    private pdfViewerService: PdfViewerService,
  ) {
  }

  ngOnInit() {
    if (this.enableRequestACopy) {
      this.canDownload$ = this.authorizationService.isAuthorized(FeatureID.CanDownload, isNotEmpty(this.bitstream) ? this.bitstream.self : undefined);
      const canRequestACopy$ = this.authorizationService.isAuthorized(FeatureID.CanRequestACopy, isNotEmpty(this.bitstream) ? this.bitstream.self : undefined);
      this.bitstreamPath$ = observableCombineLatest([this.canDownload$, canRequestACopy$]).pipe(
        map(([canDownload, canRequestACopy]) => this.getBitstreamPath(canDownload, canRequestACopy))
      );
    } else {
      this.bitstreamPath$ = observableOf(this.getBitstreamDownloadPath());
      this.canDownload$ = observableOf(true);
    }

    this.buildGalleryInfo();
    this.buildXMLUIPath();
  }

  getBitstreamPath(canDownload: boolean, canRequestACopy: boolean) {
    if (!canDownload && canRequestACopy && hasValue(this.item)) {
      return getBitstreamRequestACopyRoute(this.item, this.bitstream);
    }
    return this.getBitstreamDownloadPath();
  }

  getBitstreamDownloadPath() {
    return {
      routerLink: getBitstreamDownloadRoute(this.bitstream),
      queryParams: {}
    };
  }

  /// Build the data that we use to decide if we show this in gallery, and how
  private buildGalleryInfo(): void{
    let fileName: string = this.dsoNameService.getName( this.bitstream );

    if ( FileUtil.isImage( fileName ) ) {
      this.galleryInfo.galleryItem = true;
      this.galleryInfo.image = true;
    }

    if ( this.galleryInfo.galleryItem ){
      return;
    }

    if ( FileUtil.isVideo( fileName ) ) {
      this.galleryInfo.galleryItem = true;
      this.galleryInfo.video = true;
    }
  }

  /// Is this bitstream a pdf?
  public isPdf(): boolean{
    let name: string = this.dsoNameService.getName( this.bitstream );
    if ( name.endsWith( 'pdf' ) ){
      return true;
    } else {
      return false;
    }
  }

  /// Trigger the PDF viewer overlay dialog thru the service
  public showPdf( e: MouseEvent, href: string ): void{
    e.preventDefault();
    this.pdfViewerService.setCurrentPdf( href );
  }

  /// Trigger the gallery overlay dialog
  public showGallery( e: MouseEvent ): void {
    e.preventDefault();
    let p = this.renderer.parentNode(this.element.nativeElement);
    if ( p === null ) {
      return;
    }

    let el: HTMLElement = p as HTMLElement;
    const seq: number = Number( el.getAttribute( 'seq' ) );
    
    let idx: number = 0;

    const bundle: string = this.bitstream.bundleName;
    idx = this.galleryService.getIndexForBundle( bundle, seq );
  
    this.galleryService.open( idx );
  }

  /// Builds the XMLUI style path of the bitstream
  private buildXMLUIPath(): void{
    let itemHandle: string = this.item.handle;
    let fileName: string = this.dsoNameService.getName( this.bitstream );
    this.xmluiPath = '/bitstream/handle/' + itemHandle + '/' + fileName;
  }
}
