import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PdfViewerService } from './pdf-viewer.service';

@Component({
  selector: 'fszek-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnDestroy{

  _visible : boolean;
  pdfDocument : string = "";
  subscription : Subscription;

  public constructor(
    private pdfViewerService : PdfViewerService
  )
  {
    this._visible = false;
    this.subscription = this.pdfViewerService.currentPdf$.subscribe( ( pdf ) =>{
      this.onPdfChange( pdf );
    });
  }

  public ngOnDestroy()
  {
    this.subscription.unsubscribe();
  }

  public onPdfChange( pdf : string ) : void
  {
    this.pdfDocument = pdf;
    this._visible = true;
  }

  public isVisible() : boolean
  {
    return this._visible;
  }

  public hideViewer() : void
  {
    this._visible = false;
  }
}
